// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-1-tsx": () => import("./../src/pages/page-1.tsx" /* webpackChunkName: "component---src-pages-page-1-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-page-3-tsx": () => import("./../src/pages/page-3.tsx" /* webpackChunkName: "component---src-pages-page-3-tsx" */),
  "component---src-pages-page-4-tsx": () => import("./../src/pages/page-4.tsx" /* webpackChunkName: "component---src-pages-page-4-tsx" */),
  "component---src-pages-page-5-tsx": () => import("./../src/pages/page-5.tsx" /* webpackChunkName: "component---src-pages-page-5-tsx" */),
  "component---src-pages-page-6-tsx": () => import("./../src/pages/page-6.tsx" /* webpackChunkName: "component---src-pages-page-6-tsx" */),
  "component---src-pages-page-7-tsx": () => import("./../src/pages/page-7.tsx" /* webpackChunkName: "component---src-pages-page-7-tsx" */),
  "component---src-pages-page-8-tsx": () => import("./../src/pages/page-8.tsx" /* webpackChunkName: "component---src-pages-page-8-tsx" */),
  "component---src-pages-page-9-tsx": () => import("./../src/pages/page-9.tsx" /* webpackChunkName: "component---src-pages-page-9-tsx" */)
}

